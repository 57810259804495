.bg-black {
    background-image: url('../images/background.jpg'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    padding-top: 10px; 
    padding-bottom: 10px; 
}

.sponsors {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #111; 
}

.sponsors-title {
    margin-top: 80px; 
    text-align: center;
}

.sponsors-title-text {
    color: #dc2626; 
    font-size: 4rem; 
    font-weight: bold;
}

.gifting-partner {
    text-align: center;
    margin-top: 20px;
    background-color: #222; 
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); 
    max-width: 400px;
    width: 100%;
    margin: 20px; 
}

.gifting-title {
    color:#dc2626; 
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.partner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner-logo {
    width: 120px; 
    height: auto;
    margin-bottom: 15px;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

.partner-name {
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffffff;
}

@media screen and (max-width: 600px) {
    .partner-logo {
        width: 90%; 
    }

    .gifting-title {
        font-size: 1.5rem; 
    }

    .partner-name {
        font-size: 1.2rem; 
    }
}
