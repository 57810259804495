.navbarr {
    color: #fff;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    /* box-shadow: 0 0 30px hsla(0, 1%, 64%, 0.456); */
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    font-size: 17px;
}

.menu-btn {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    margin-top: 10px;
    z-index: 1;
    user-select: none;
}

.menu-btn input:checked ~ div {
    transform: none;
    opacity: 1;
}

.menu-btn input {
    display: block;
    width: 30px;
    height: 25px;
    left: 28px;
    bottom: -3px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    opacity: 0;
    z-index: 2;
}

/* a {
    text-decoration: none !important;
  color: white !important;
} */

.menu-btn span {
    display: block;
    width: 33px;
    height: 4px;
    bottom: 22px;
    left: 37px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: -1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.menu-btn input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #FFFFFF;
}

.menu-btn input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.menu-btn span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

.menu-link.hide {
    display: none;
}

.menu-btn input:checked ~ span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

.menu {
    background-color: rgba(23, 23, 23, 0.9);
    position: fixed;
    left: 0px;
    width: 100vw;
    height: 100vh;
    top: -1px;
    z-index: -5;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    overflow-y: scroll;
}

@media screen and (max-width: 600px) {
    /* .navLogo img{
        width: 60px!important;
        height: 70px!important;
    } */
}

.menu-links {
    width: 80% !important;
    margin-left: 10%;
    padding-top: 130px;
}

.menu-link {
    background-color: #e03a3c;
    z-index: 999;
    color: #FFFFFF;
    border: none;
    font-weight: 600;
    font-style: normal;
    /* font-size: 4.7vw; */
    width: 80vw;
    display: flex;
    align-items: center;
    gap: 2vmin;
    /* background: #007691; */
    padding-top: 0.5vw;
    padding-bottom: 0.4vw;
    padding-left: 2vw;
    margin-bottom: 3vw;
    text-align: start;
    clip-path: polygon(0 0, 0 100%, 60% 100%, 52% 0, 54% 0, 62% 100%, 62% 100%, 70% 100%, 62% 0, 64% 0, 72% 100%, 80% 100%, 80% 100%, 72% 0, 74% 0, 82% 100%, 82% 100%, 90% 100%, 82% 0, 84% 0, 92% 100%, 92% 100%, 100% 100%, 92% 0);
}

.link {
    transition: .3s all ease-in-out;
}

a:hover {
    cursor: pointer;
}

.link:hover {
    background-color: #e03a3c;
    padding: 10px 20px;
    border-radius: 20px;
    transition: .3s all ease-in-out;
}

.navbarContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.navLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-right: 30px;
    font-size: 17px;
}

/* .navLogo img{
    width: 70px;
    height: 80px;
    margin: 0 1em;
    margin-top: 10px;
} */
.dropdown-toggle::after {
    color: white;
}

.main_logo {
    width: 13rem;
    margin: 0 1em;
    /* margin-top: 10px;     */
}