.feedback-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px; 
    height: 230vh; 
    max-width: 1000px; 
    width: 100%; 
    margin: 0 auto; 
    background-image: url('../images/background.jpg'); 
    background-size: cover; 
    background-position: center; 
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: white; 
  }
  
  .feedback-form-container h2 {
    color: #ff2b06;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  #feedback-form-iframe {
    width: 100%;
    height: calc(100% - 100px); 
    border: none;
  }
  
  @media (max-width: 768px) {
    .feedback-form-container {
        padding: 40px 10px; 
        height: auto; 
        max-width: 90%; 
    }

    .feedback-form-container h2 {
        font-size: 1.5rem; 
    }

    #feedback-form-iframe {
        height: 70vh; 
    }
}