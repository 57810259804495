.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Translucent background */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  width: 100%; /* Full width for larger screens */
  max-width: 800px; /* Constrain width */
  backdrop-filter: blur(10px); /* Adds blur to the background */
  overflow: hidden; /* Ensures that the content does not go out of the box */
}

.time-box {
  text-align: center;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1); /* Slight translucent box */
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex: 1; /* Flex allows the boxes to shrink/expand */
  min-width: 60px;
}

.time-value {
  font-size: 2.5rem;
  font-weight: bold;
  white-space: nowrap; /* Prevents wrapping */
}

.time-label {
  font-size: 1rem;
  margin-top: 5px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
.timer-container {
  flex-direction: row;  /* Stack the timer elements vertically on small screens */
  padding: 10px;
  max-width: 100%;
}

.time-box {
  margin-bottom: 10px; /* Add spacing between stacked time boxes */
  padding: 10px;
}

.time-label {
  font-size: 0.7rem; /* Smaller label for visibility */
}
}

@media (max-width: 400px) {
.timer-container {
  /* flex-wrap: wrap; Allow wrapping for small screens */
  flex-direction: row;
  padding: 5px;
}

.time-box {
  min-width: 45px; /* Reduce minimum width for smaller screens */
  padding: 8px;
  margin: 0 5px;
  flex-basis: 40%;
}

.time-value {
  font-size: 1.2rem; /* Reduce font size for smaller screens */
}

.time-label {
  font-size: 0.6rem; /* Smaller label for visibility */
}
}
