.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .our-team {
    margin-bottom: 30px;
    height: 300px;
    width: 250px;
    background-color: rgb(248, 241, 241);
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    box-shadow: 3px 5px 30px hsla(0, 27%, 92%, 0.842);
    transition: all 0.5s ease-in-out;
  }

  .our-team:hover {
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
  }
  
  .our-team .picture {
    display: inline-block;
    height: 180px;
    width: 180px;
    margin-top: 25px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color:rgba(224, 52, 52, 0.78);
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  
  .our-team:hover .picture::before {
    height: 100%;
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 14px rgb(46, 45, 45);
    transform: scale(0.7);
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: rgb(14, 14, 14);
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: rgb(176, 28, 28);
    position: absolute;
    bottom: -100px;
    opacity: 0.9;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  
  .our-team:hover .social {
    bottom: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color:rgb(150, 62, 62);
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  
  .our-team .social li a:hover {
    color: #301a0f;
    background-color:rgba(225, 222, 222, 0.865);
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .icon{
    color:black;
  }
  /* a:hover .icon{
    transition-delay: 0ms;
    transition: ease-in-out;
    transition-duration: 300ms;

  } */