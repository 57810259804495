@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer {
    background-color: #000;
    color: #fff;
    padding: 70px 50px;
    width: 100%;
    /* text-align: center; */
    font-size: 17px;
    font-weight: 300;
    /* height: 500px; */
    /* letter-spacing: 1px; */
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    gap: 40px;
    position: relative;
    bottom: 0;
}

.logo {
    width: 20rem;
}

.footer_links {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 20px;
}

.footer_social {
    display: flex;
    justify-content: space-between;
    width: 10%;
    /* padding: 0 20px; */
}

@media screen and (max-width: 768px) {
    .footer_links {
        width: 100%;
        gap: 20px;
    }
    .footer_social {
        width: 50%;
    }
}

.red {
    color: red;
}

.gray {
    color: gray;
}

.line {
    color: white!important;
    background-color: white;
}

.team_link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.team_link a {
    text-align: center; 
    color: inherit; 
    text-decoration: underline;
}